import { FormEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import FormButton, { FormButtonProps } from "../form/FormButton";
import { BsX } from "react-icons/bs";

export * from "./modal";

export interface ConfirmationModalProps {
  warningMessage?: JSX.Element | string;
  title: JSX.Element | string;
  actionButtonOption: FormButtonProps;
  className?: string;
  onClose?(): void;
  onCloseClicked?(): void;
  cancelText?: string;
  showXClose?: boolean;
}

/**
 * Modal pop up
 *
 * @param warningMessage string
 * @param title string | JSX.Element
 * @param actionButtonOption FormButtonProps
 * @param className
 * @param onClose void
 */
const usePopUpModal = (defaultVisibility = false) => {
  const [visibility, setVisibility] = useState(defaultVisibility);
  const { t } = useTranslation();

  // Open Modal
  const open = () => {
    setVisibility(true);
  };

  // Close Modal
  const close = () => {
    setVisibility(false);
  };

  // Modal design according to the figma
  const ConfirmationModal: React.FC<ConfirmationModalProps> = useCallback(
    ({
      warningMessage,
      actionButtonOption,
      title,
      className,
      onClose,
      onCloseClicked,
      cancelText,
      showXClose,
    }) => {
      const handleAction = (e: FormEvent<HTMLButtonElement>) => {
        actionButtonOption?.onPress?.(e);
        close();
      };
      return (
        <div
          className={`
          ${!visibility ? "hidden" : ""}
          overflow-y-auto overflow-x-hidden flex flex-col items-center fixed top-0 right-0 left-0 md:inset-0 h-modal md:h-full`}
          style={{ zIndex: 10000, backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div
            className={`
            relative p-4 w-auto w-xl h-screen
            ${className}
          `}
          >
            <div className='relative bg-tertiary-10 py-10 md:px-20 top-[25%] rounded-lg shadow flex flex-col items-center'>
              {showXClose && (
                <div
                  onClick={() => {
                    close();
                    onClose?.();
                  }}
                  className=' absolute top-1 right-1'
                >
                  <BsX color='#000' size={30} />
                </div>
              )}

              <div className='text-neutral-raven text-xl md:text-2xl text-center font-gellix font-semibold'>
                {title}
              </div>
              <div className='p-6 text-center'>
                <div className='mb-5 text-md font-normal text-gray-500 dark:text-gray-400'>
                  {warningMessage}
                </div>
                <div className='flex flex-col items-center justify-center w-full mt-10'>
                  <FormButton {...actionButtonOption} onPress={handleAction} />

                  <div className='mt-13'>
                    <FormButton
                      onPress={
                        onCloseClicked
                          ? onCloseClicked
                          : () => {
                              close();
                              onClose?.();
                            }
                      }
                      title={cancelText ?? (t("Cancel") as string)}
                      outline
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
    [visibility],
  );
  return { open, close, ConfirmationModal };
};

export default usePopUpModal;
